import React from 'react';
import logo from './logo.svg';
import './App.css';


class App extends React.Component{
  render(){
  return (

<div>
    <div className="content" >

        <div className="content__item">
          <button className="content__button" style={{color:'wheat',fontSize:'1.8rem'}}> Take me in </button>
        </div>
        <div className="content__item content__item--details">
          <h3 className="content__location"> WELCOME TO  </h3><br />
          <h2 className="content__title" style={{fontSize:'30px'}}>ALPHALIBERTEE</h2><br />

          <p className="content__date">
            Airdrop   17th May
          </p>

        </div>

        <div className="content__item content__item--details content__item--columns">

          <div className="content__inner" style={{textAlign: 'left'}}>
            
             <span className="content__inner-item" style={{color:'#7188fe'}}/>PLAY TO EARN<br/>
            <span className="content__inner-item"/>Made-to-Learn 
            

            <div className="content2">
        <button className="button2">
          <div className="button2__deco">
            <div className="button2__deco-filler"></div>
          </div>
          <span className="button2__text">
            <span className="button2__text-inner"/>Connect
          </span>
        </button>
      </div>
          </div>



          <div className="content__inner"/>
            <img className="imgA1"  src="buttons/Alpha.gif" alt="Alpha" style={{width:'70%', height:'100%'}}/>

          
            


        </div>

        </div>







      <div className="frame">




        <div className="frame__title-wrap">

          <div className="row">
            <div className="col-sm"/>
            <h1 className="frame__title">Alphalibertee © 2022 All rights reserved.</h1><br />
           
          
          <div className="col-sm">
            <div className="frame__links ">
              
        <a className="hoveff" href="">
        <button className="button3">
          <div className="button3__filler"/>
          <span className="button3__text">
            <span className="button3__text-inner"/>DAISY
          </span>
          </button>
          </a>
  
            
            <a className="hoveff" href="" />SINGIN
            <a className="hoveff" href=""/>SINGUP
            <a className="hoveff" href=""/>TEAM
          </div>
          </div>
          

           <div className="col-sm rightit">
             <div className="frame__links">
                    <a href="#"><i className="fa fa-instagram"></i></a>
                    <a href="#"><i className="fa fa-linkedin"></i></a>
                    </div></div>
                    
                  </div>
          </div>


    

        <div className="frame__deco"/>
          <span className="frame__deco-inner"/>ALPHALIBERTE
         
      </div> 
</div>
  );}}


export default App;
